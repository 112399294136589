<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">Calculate the reaction enthalpy for the reaction below:</p>

      <v-simple-table class="mb-3 pl-10" style="width: 520px">
        <tr>
          <td style="width: 350px">
            <chemical-latex :content="rxnTotal" />
          </td>
          <td style="width: 170px">
            <stemble-latex content="$\Delta \text{H}_{\text{r}}\text{ = ? kJ}$" />
          </td>
        </tr>
      </v-simple-table>

      <p class="mb-2">given the following information</p>

      <v-simple-table class="mb-4 pl-10" style="width: 520px">
        <tr>
          <td style="width: 350px">
            <chemical-latex :content="rxn1" />
          </td>
          <td style="width: 170px">
            <stemble-latex content="$\Delta \text{H}_1\text{ = }$" />
            <number-value :value="enthalpy1" unit="\text{kJ}" />
          </td>
        </tr>
        <tr>
          <td>
            <chemical-latex :content="rxn2" />
          </td>
          <td>
            <stemble-latex content="$\Delta \text{H}_2\text{ = }$" />
            <number-value :value="enthalpy2" unit="\text{kJ}" />
          </td>
        </tr>
        <tr>
          <td>
            <chemical-latex :content="rxn3" />
          </td>
          <td>
            <stemble-latex content="$\Delta \text{H}_3\text{ = }$" />
            <number-value :value="enthalpy3" unit="\text{kJ}" />
          </td>
        </tr>
      </v-simple-table>

      <calculation-input
        v-model="inputs.Hrxn"
        class="mb-4"
        prepend-text="$\Delta\text{H}_{\text{r}}:$"
        append-text="$\text{kJ}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question264',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Hrxn: null,
      },
      options: [
        {expression: '$\\text{Exothermic}$', value: 'exo'},
        {expression: '$\\text{Endothermic}$', value: 'endo'},
      ],
    };
  },
  computed: {
    enthalpy1() {
      return this.taskState.getValueBySymbol('enthalpy1').content;
    },
    enthalpy2() {
      return this.taskState.getValueBySymbol('enthalpy2').content;
    },
    enthalpy3() {
      return this.taskState.getValueBySymbol('enthalpy3').content;
    },
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content;
    },
    versionData() {
      switch (this.versionNumber.value) {
        case 1:
          return {
            rxnTotal: '2 A(s) + 2B(s) -> 2 C(s) + D(g)', // Mult all by 2, invert rxn 1
            rxn1: 'X(s) + 1/2 D(g) -> A(s)',
            rxn2: 'Y(g) + B(s) -> Z(g)',
            rxn3: 'X(s) + Z(g) -> C(s) + Y(g)',
          };
        case 2:
          return {
            rxnTotal: '2 C(s) + D(g) -> 2 A(s) + 2B(s)', // Mult all by 2, invert rxn 2 and 3
            rxn1: 'X(s) + 1/2 D(g) -> A(s)',
            rxn2: 'Y(g) + B(s) -> Z(g)',
            rxn3: 'X(s) + Z(g) -> C(s) + Y(g)',
          };
        case 3:
          return {
            rxnTotal: 'A(l) + 3 B(g) -> C(g) + 2D(g)', // Invert rxn 1 and 2, mult rxn2 by 2
            rxn1: 'C(g) -> X(s) + B(g)',
            rxn2: 'D(g) -> Y(s) + B(g)',
            rxn3: 'A(l) -> X(s) + 2 Y(s)',
          };
        case 4:
          return {
            rxnTotal: 'C(g) + 2D(g) -> A(l) + 3 B(g)', // Invert rxn 3, mult rxn2 by 2
            rxn1: 'C(g) -> X(s) + B(g)',
            rxn2: 'D(g) -> Y(s) + B(g)',
            rxn3: 'A(l) -> X(s) + 2 Y(s)',
          };
        default:
          return {
            rxnTotal: '',
            rxn1: '',
            rxn2: '',
            rxn3: '',
          };
      }
    },
    rxnTotal() {
      return this.versionData.rxnTotal;
    },
    rxn1() {
      return this.versionData.rxn1;
    },
    rxn2() {
      return this.versionData.rxn2;
    },
    rxn3() {
      return this.versionData.rxn3;
    },
  },
};
</script>
